import axios from "axios";

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: "https://api-uat-b2b.vharvest.in/",
  // timeout: 5000, // Set the timeout for requests (optional)
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("token"); // Retrieve the token from localStorage
  if (token) {
    config.headers["Authorization"] = `Token ${token}`; // Add the token to the request headers
  }
  //  config.headers['domain'] = 'staging-vharvest-b2b.shopdiy.in';
  config.headers["domain"] = window.location.hostname.replace(
    /\bdashboard.\b/g,
    ""
  );
  return config;
});

export default axiosInstance;
