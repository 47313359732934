import React, { useEffect, useState } from "react";
import { DashboardLayout, PopupModal, FormParser } from "../Components";
import {
  MdChevronRight,
  MdClose,
  MdOutlineSearch,
  MdCheck,
} from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { useNavigate, useParams, useLocation } from "react-router";
import axiosConfig from "../Service/axiosConfig";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import useDebouncedValue from "../Utils/Debounce";
import Masters from "../Components/masters.json";
import ToggleSwitch from "./ToggleSwitch";

const CreateQuotation = () => {
  const [LoadingButton, setLoadingButton] = useState();
  const [loading, setLoading] = useState(false);
  const { quotation_id } = useParams();
  const location = useLocation();
  const { client_data, grn_data } = location.state || {};
  console.log("state check  :  ", grn_data, client_data);
  const navigate = useNavigate();
  const [grn, setGrn] = useState({
    products: [],
    date_of_supply:
      grn_data?.date_of_supply || new Date().toLocaleDateString("en-CA"),
  });
  const [expiry, setExpiry] = useState("");
  const [clients, setClient] = useState([]);
  const [leads, setLeads] = useState([]);
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebouncedValue(search, 500);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedLead, setSelectedLead] = useState(null);
  const [products, setProducts] = useState([]);
  const [count, setCount] = useState(0);
  const [popupType, setpopupType] = useState("");
  const [Error, SetError] = useState("");
  const [message, setMessage] = useState(null);
  const [showAddLead, setShowAddLead] = useState(false);
  const [oneTime, setOneTime] = useState(grn_data?.one_time || false);
  const addLead = Masters.AddLead;

  const handleClose = () => {
    setShow(false);
    setSearch("");
    setPage(1);
  };
  const handleCancel = () => {
    if (quotation_id) {
      setShow(false);
    } else {
      setShow(false);
      setSearch("");
      setPage(1);
      setGrn({
        products: [],
        ordered_date: null,
        delivered_date: null,
      });
    }
  };

  const handleSearch = async (value) => {
    setSearch(value);
    setPage(1);
  };

  const handleScroll = (e) => {
    const { clientHeight, scrollTop, scrollHeight } = e.target;
    if (
      clientHeight + scrollTop >= scrollHeight - 1 &&
      Math.ceil(count / 10) > page
    ) {
      setPage(page + 1);
    }
  };

  const handleToggle = (e, row) => {
    e.target.checked ? setOneTime(true) : setOneTime(false);
  };
  const GetClients = async () => {
    try {
      const response = await axiosConfig.get(
        "/clients/clients/?is_suspended=false"
      );
      if (response.data.results.length > 0) {
        let client_options = response.data.results.map((client) => ({
          label: client.client_name,
          value: client.id,
        }));
        setClient(client_options);
      }
    } catch (error) {
      console.log("Error while fetching clients", error);
    }
  };

  const GetLeads = async () => {
    try {
      const response = await axiosConfig.get(`/blogapp/leads/`);
      setLeads(
        response.data.results.map((lead) => ({
          label: lead.name,
          value: lead.id,
        }))
      );
    } catch (error) {
      console.log("Error while fetching leads", error);
    }
  };

  const handleVendorChange = (selectedOption, name) => {
    if (name === "client") {
      setSelectedClient(selectedOption);
      setGrn((prev) => ({
        ...prev,
        client: selectedOption.value,
        products: [],
      }));
    } else if (name === "lead") {
      setSelectedLead(selectedOption);
    }
  };

  const handleAdd = () => {
    (selectedClient || selectedLead) && setShow(true);
  };

  const HandleQtyChange = (e, item) => {
    const productIndex = grn.products.findIndex(
      (product) => product.id === item.id
    );
    if (productIndex !== -1) {
      let updatedProducts = [...grn.products];
      console.log(typeof e.target.value, item, "type check");
      const value = parseFloat(e.target.value);
      console.log("value check : ", value);
      if (!isNaN(value) && value > -0.01) {
        updatedProducts[productIndex] = {
          ...updatedProducts[productIndex],
          min_qty: value,
          // price: item.price
        };
      } else {
        updatedProducts[productIndex] = {
          ...updatedProducts[productIndex],
          min_qty: "",
          // price: ''
        };
      }
      setGrn((prev) => ({
        ...prev,
        products: updatedProducts,
      }));
    }
  };

  const HandlePriceChange = (e, item) => {
    const productIndex = grn.products.findIndex(
      (product) => product.id === item.id
    );
    if (productIndex !== -1) {
      let updatedProducts = [...grn.products];
      const value = parseFloat(e.target.value);
      if (!isNaN(value) && value > -0.01) {
        updatedProducts[productIndex] = {
          ...updatedProducts[productIndex],
          price: value,
        };
      } else {
        updatedProducts[productIndex] = {
          ...updatedProducts[productIndex],
          price: "",
        };
      }
      setGrn((prev) => ({
        ...prev,
        products: updatedProducts,
      }));
    }
  };

  const handleSelect = (item) => {
    const existingProductIndex = grn.products.findIndex(
      (product) => product.id === item.id
    );
    if (item.available) {
      if (existingProductIndex !== -1) {
        setGrn((prev) => ({
          ...prev,
          products: prev.products.filter((product) => product.id !== item.id),
        }));
      } else {
        console.log(grn);
        const newProduct = {
          id: item.id,
          min_qty: 1,
          image: item.image ? item.image.image : "",
          price: item.price,
          name: item.name,
          available: item.available,
        };
        setGrn((prev) => ({
          ...prev,
          products: [...prev.products, newProduct],
        }));
        const existing = {
          ...grn,
          products: [...grn.products, newProduct],
        };
        console.log("Clicked item : ", item.name, existing);
      }
    }
  };

  const handleDelete = (id) => {
    const productIndex = grn.products.findIndex((product) => product.id === id);
    const updatedProducts = [...grn.products];
    updatedProducts.splice(productIndex, 1);
    setGrn((prev) => ({
      ...prev,
      products: updatedProducts,
    }));
  };

  const HandleBlur = (e, item) => {
    const productIndex = grn.products.findIndex(
      (product) => product.id === item.id
    );
    if (productIndex !== -1) {
      let updatedProducts = [...grn.products];
      const value = parseFloat(e.target.value);
      if (value === 0 || isNaN(value)) {
        updatedProducts.splice(productIndex, 1);
      }
      setGrn((prev) => ({
        ...prev,
        products: updatedProducts,
      }));
    }
  };

  const GetProducts = async () => {
    setLoading(true);
    try {
      const response = await axiosConfig.get(
        `catlog/products/?page_size=10&page=${page}&name=${debouncedSearchTerm}&is_suspended=false`
      );
      setCount(response.data.count);
      if (page === 1) {
        setProducts(response.data.results);
      } else {
        setProducts((prev) => {
          // Create a set of unique product IDs from the existing products (prev)
          const existingProductIds = new Set(
            prev.map((prevprod) => prevprod.id)
          );

          // Filter out products from the new results that already exist in prev
          const uniqueNewProducts = response.data.results.filter(
            (prod) => !existingProductIds.has(prod.id)
          );
          const nonUniqueProducts = response.data.results.filter((prod) =>
            existingProductIds.has(prod.id)
          );
          console.log(nonUniqueProducts, page, "non unique");

          // Combine the unique products with the existing products
          // return [...prev, ...uniqueNewProducts];
          return [...prev, ...uniqueNewProducts];
        });
      }
      setLoading(false);
    } catch (error) {
      console.log("Error getting products:", error);
      setLoading(false);
    }
  };

  const GetGrnData = async () => {
    try {
      const response = await axiosConfig.get(
        `/accounts/quote-items/?quote=${quotation_id}&page_size=1000`
      );
      let products_info = response.data.results.map((grn_item) => {
        return {
          id: grn_item.product.id,
          min_qty: grn_item.qty || 1,
          price: grn_item.product.price,
          image: grn_item.product.image ? grn_item.product.image.image : "",
          name: grn_item.product.name,
        };
      });
      setGrn((prev) => ({
        ...prev,
        products: products_info,
      }));
      if (grn_data?.client) {
        setSelectedClient({
          label: grn_data.client.client_name,
          value: grn_data.client.id,
        });
      }
      if (grn_data?.lead) {
        setSelectedLead({
          label: grn_data.lead.name,
          value: grn_data.lead.id,
        });
      }

      if (grn_data?.expiration_date) {
        let expiry =
          grn_data.expiration_date.split("T")[0].split("-")[0] +
          "-" +
          grn_data.expiration_date.split("T")[0].split("-")[1] +
          "-" +
          grn_data.expiration_date.split("T")[0].split("-")[2];
        setExpiry(expiry);
      }
      console.log("grn data", response.data.results);
    } catch (error) {
      console.log(error);
    }
  };

  const createGrn = async () => {
    if (grn.products.length > 0) {
      const product = grn.products
        .map((product) => ({
          id: product.id,
          qty: product.min_qty,
          price: product.price,
        }))
        .filter((product) => product.qty !== 0 && product.qty !== "");

      const order_to_post = {
        ...grn,
        products: product,
        one_time: oneTime,
        client: selectedClient?.value,
        lead: selectedLead?.value,
        expiration_date: expiry,
      };

      // Give preference to lead
      if (order_to_post.lead) {
        delete order_to_post.client; // Remove client if lead is present
      } else if (!order_to_post.lead && order_to_post.client === null) {
        delete order_to_post.client; // Remove client if it's null
      }

      if (order_to_post.lead === null) {
        delete order_to_post.lead; // Remove lead if it's null
      }

      setLoadingButton(true);
      try {
        if (quotation_id) {
          await axiosConfig.put(
            `/accounts/quotes/${quotation_id}/`,
            order_to_post,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          setpopupType("success");
          setLoadingButton(false);
          setMessage("Quotation Updated Successfully");
          navigate("/dashboard/masters/quotations");
        } else {
          await axiosConfig.post(`/accounts/quotes/`, order_to_post, {
            headers: {
              "Content-Type": "application/json",
            },
          });
          setLoadingButton(false);
          setpopupType("success");
          setMessage("Quotation Created Successfully");
          navigate("/dashboard/masters/quotations");
        }
      } catch (error) {
        console.log("Error creating/updating grn:", error);
        setLoadingButton(false);
        setpopupType("error");
        if (error.message) {
          console.log("caught network error");
          SetError(error.message);
        }
        if (
          Object.keys(error.response.data)[0] ===
          "The following products are not available in the store:"
        ) {
          SetError(
            "Store is unassigned for this client / Some products are not available in the store"
          );
        }
        if (error.response.data.error) {
          SetError(error.response.data.error);
        }
      }
    }
  };

  const handleAddLead = async (data) => {
    try {
      console.log(data);
      await axiosConfig.post(`/blogapp/leads/`, data);
      setShowAddLead(false);
      setpopupType("success");
      setMessage("Lead added successfully");
      GetLeads();
    } catch (error) {
      console.log(error);
      SetError("Error adding lead");
    }
  };

  useEffect(() => {
    GetProducts();
    GetClients();
    GetLeads();
    if (quotation_id && grn.products.length === 0) {
      GetGrnData();
    }
  }, [debouncedSearchTerm, page]);

  return (
    <>
      <DashboardLayout>
        <div className="create-ord-top">
          <div className="create-ord-left">
            <div>Quotation</div>
            <MdChevronRight />
            {quotation_id ? (
              <div style={{ color: "black" }}>Modify Quotation</div>
            ) : (
              <div style={{ color: "black" }}>Create Quotation</div>
            )}
            <MdChevronRight />
            <ToggleSwitch
              row={{ id: "onetime" }}
              checked={oneTime}
              handleToggle={handleToggle}
            />
            <label>One time</label>
          </div>
          <div className="create-ord-right">
            <button
              className="btn btn-white"
              onClick={() => navigate("/dashboard/masters/quotations")}
            >
              Cancel
            </button>
            {quotation_id ? (
              <button
                className={
                  grn.products.length > 0
                    ? "btn btn-primary"
                    : "btn btn-inactive"
                }
                onClick={createGrn}
              >
                {LoadingButton ? (
                  <div>
                    <img
                      src={require("../Components/FormParser/images/loader.gif")}
                      alt="Loading..."
                      className="loader-image"
                    />
                  </div>
                ) : (
                  "Update Quotation"
                )}
              </button>
            ) : (
              <button
                className={
                  grn.products.length > 0
                    ? "btn btn-primary"
                    : "btn btn-inactive"
                }
                onClick={createGrn}
              >
                {LoadingButton ? (
                  <div>
                    <img
                      src={require("../Components/FormParser/images/loader.gif")}
                      alt="Loading..."
                      className="loader-image"
                    />
                  </div>
                ) : (
                  "Create Quotation"
                )}
              </button>
            )}
          </div>
        </div>
        <div className="row m_20">
          <div className="col-6">
            <div className="row">
              <div className="col-6">
                <p>Client</p>
                <Select
                  placeholder="Client"
                  options={clients}
                  value={selectedClient}
                  onChange={(selectedOption) =>
                    handleVendorChange(selectedOption, "client")
                  }
                  className="f_13_500"
                  isDisabled={quotation_id ? true : false}
                />
              </div>
              <div className="col-6">
                <p>Expiration Date</p>
                <input
                  type="date"
                  className="form-control f_13_500"
                  value={expiry}
                  onChange={(e) => setExpiry(e.target.value)}
                  disabled={grn_data?.expiration_date ? true : false}
                />
              </div>
            </div>
            <div className="create-ord-card">
              <div className="create-card-top">
                <div className="f_14_500">
                  Items {grn.products.length > 0 && `(${grn.products.length})`}
                </div>
                <button
                  className={
                    selectedClient || selectedLead
                      ? "btn btn-primary"
                      : "btn btn-inactive"
                  }
                  onClick={() => handleAdd()}
                >
                  + Add Item
                </button>
              </div>
              <div>
                {grn.products.length > 0 ? (
                  <div className="ord-items">
                    {grn.products.map((product) => {
                      return (
                        <div className="product-main" style={{ gap: "0px" }}>
                          <div className="product-info">
                            <div className="img-contaoner-modal">
                              <img src={product.image} />
                            </div>
                            <div className="product-stock">
                              <div className="product-name">{product.name}</div>
                              <div
                                className="f_14_400"
                                style={{ color: "#8C8C8C" }}
                              >
                                Price: ₹{product.price} |{" "}
                                {product.available
                                  ? "In Stock"
                                  : "Out of Stock"}
                              </div>
                            </div>
                          </div>
                          <div className="product-info">
                            <input
                              type="number"
                              value={product.min_qty}
                              className="product-qty"
                              onChange={(e) => HandleQtyChange(e, product)}
                              onWheel={(e) => e.target.blur()}
                              onBlur={(e) => HandleBlur(e, product)}
                              disabled={!oneTime}
                            />
                            {/* <div className='f_12_400' style={{ width: '60px' }}>₹{product.price}</div> */}
                            <input
                              type="number"
                              value={product.price}
                              className="product-qty"
                              onChange={(e) => HandlePriceChange(e, product)}
                              onWheel={(e) => e.target.blur()}
                              onBlur={(e) => HandleBlur(e, product)}
                            />
                            <RiDeleteBinLine
                              size={15}
                              color="#1B7D78"
                              className="delete-icon"
                              onClick={() => handleDelete(product.id)}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div
                    style={{
                      textAlign: "center",
                      paddingTop: "60px",
                      paddingBottom: "120px",
                    }}
                  >
                    Add items to the Quotation
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-6">
            <p>Leads</p>
            <div className="d-flex">
              {/* <input
                type="date"
                className="form-control flex-1 me-3"
                value={grn.date_of_supply}
                onChange={(e) =>
                  setGrn((prev) => ({
                    ...prev,
                    date_of_supply: e.target.value,
                  }))
                } */}
              <Select
                placeholder="Leads"
                options={leads}
                value={selectedLead}
                onChange={(selectedOption) =>
                  handleVendorChange(selectedOption, "lead")
                }
                className="f_13_500 flex-1 me-3"
                isDisabled={quotation_id ? true : false}
              />
              <button
                className="btn btn-primary"
                style={{ height: "unset" }}
                onClick={() => setShowAddLead(true)}
              >
                Create Lead
              </button>
            </div>
            <div className="create-ord-card">
              <div className="create-card-top">
                <div className="f_14_500">Quotation Summary</div>
              </div>
              <div>
                {grn.products.length > 0 ? (
                  <div>
                    <div className="subtotal">
                      <div className="f_14_400 rowBetweenCenter">
                        <div>Subtotal</div>
                        <div>
                          ₹
                          {grn.products
                            .reduce(
                              (total, product) =>
                                total + product.price * product.min_qty,
                              0
                            )
                            .toFixed(2)}
                        </div>
                      </div>
                      <div className="f_14_400 rowBetweenCenter">
                        <div>Tax</div>
                        <div>₹0.00</div>
                      </div>
                    </div>
                    <div className="f_14_500 total rowBetweenCenter">
                      <div>Total</div>
                      <div>
                        ₹
                        {grn.products
                          .reduce(
                            (total, product) =>
                              total + product.price * product.min_qty,
                            0
                          )
                          .toFixed(2)}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      paddingLeft: "20px",
                      paddingTop: "20px",
                      paddingBottom: "20px",
                    }}
                  >
                    No items have been added yet
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal show={show} onHide={handleClose} centered size="lg">
          <Modal.Body>
            <div className="add-header">
              <div>Add a Product</div>
              <MdClose onClick={() => handleClose()} />
            </div>
            <div className="search-input">
              <MdOutlineSearch size={17} />
              <input
                type="text"
                placeholder="Search..."
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
            <div className="product-list" onScroll={(e) => handleScroll(e)}>
              {products.length > 0 &&
                products.map((product) => {
                  return (
                    <div
                      className="add-header"
                      onClick={() => handleSelect(product)}
                    >
                      <div className="product-info">
                        <div className="rowBetweenCenter gap-20">
                          <div className="item-checkbox">
                            {grn.products.some((p) => p.id === product.id) && (
                              <MdCheck color="#1B7D78" />
                            )}
                          </div>
                          <div className="img-contaoner-modal">
                            <img
                              src={product.image ? product.image.image : ""}
                              alt="product"
                            />
                          </div>
                          <div>
                            <div>{product.name}</div>
                          </div>
                        </div>
                        {!product.available ? (
                          <div className="out-of-stock">out of stock</div>
                        ) : grn.products.some((p) => p.id === product.id) ? (
                          <input
                            type="number"
                            defaultValue={0}
                            value={
                              grn.products.find((p) => p.id === product.id)
                                .min_qty
                            }
                            className="product-qty"
                            onChange={(e) => HandleQtyChange(e, product)}
                            onClick={(e) => e.stopPropagation()}
                            onWheel={(e) => e.target.blur()}
                            onBlur={(e) => HandleBlur(e, product)}
                            disabled={!oneTime}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                      {/* <div className='f_12_400'>₹{product.price}.00</div> */}
                      {grn.products.find((p) => p.id === product.id) ? (
                        <input
                          type="number"
                          className="product-qty"
                          value={
                            grn.products.find((p) => p.id === product.id).price
                          }
                          onChange={(e) => HandlePriceChange(e, product)}
                          placeholder="price"
                          onClick={(e) => e.stopPropagation()}
                          onWheel={(e) => e.target.blur()}
                          onBlur={(e) => HandleBlur(e, product)}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  );
                })}
              {loading && (
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    src={require("../Components/FormParser/images/loader.gif")}
                    style={{ width: "30px", height: "30px" }}
                    alt="loader"
                  />
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary" onClick={() => handleCancel()}>
              cancel
            </button>
            <button
              className={
                grn.products.length > 0 ? "btn btn-primary" : "btn btn-inactive"
              }
              onClick={handleClose}
            >
              Add to Quotation
            </button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="lg"
          show={showAddLead}
          onHide={() => setShowAddLead(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Add Lead
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormParser
              modelObject={addLead}
              buttonTitle="Create"
              formSubmit={handleAddLead}
              error={Error}
            />
          </Modal.Body>
        </Modal>

        {popupType != "" && (
          <PopupModal
            popupType={popupType}
            setpopupType={setpopupType}
            error={Error}
            Message={message}
          />
        )}
      </DashboardLayout>
    </>
  );
};

export { CreateQuotation };
