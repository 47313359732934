import React from "react";
import { NavLink } from "react-router-dom";
import {
  MdHistory,
  MdOutlineCurrencyRupee,
  MdOutlineDateRange,
  MdOutlineSell,
} from "react-icons/md";
import {
  AiOutlineBarChart,
  AiOutlineLineChart,
  AiOutlinePieChart,
} from "react-icons/ai";
import { GoDesktopDownload } from "react-icons/go";
import { TbReplace } from "react-icons/tb";
import { TbReportMedical } from "react-icons/tb";
import { LiaCalendarDaySolid } from "react-icons/lia";
const ReportsNav = () => {
  return (
    <div className="categorie-header">
      <NavLink to="/dashboard/reports" className="categorie-subtitle">
        <div className="child-title">
          <MdHistory size={24} className="childnav-icon" />
          <p className="childnav-name">Orders History</p>
        </div>
      </NavLink>
      <NavLink
        to="/dashboard/masters/consolidated_reports"
        className="categorie-subtitle"
      >
        <div className="child-title">
          <GoDesktopDownload size={24} className="childnav-icon" />
          <p className="childnav-name">Consolidated</p>
        </div>
      </NavLink>
      <NavLink
        to="/dashboard/masters/item_client_reports"
        className="categorie-subtitle"
      >
        <div className="child-title">
          <AiOutlineBarChart size={24} className="childnav-icon" />
          <p className="childnav-name">Item & Client Reports</p>
        </div>
      </NavLink>

      <NavLink
        to="/dashboard/masters/sales_report"
        className="categorie-subtitle"
      >
        <div className="child-title">
          <MdOutlineSell size={24} className="childnav-icon" />
          <p className="childnav-name">Sales Report</p>
        </div>
      </NavLink>
      <NavLink
        to="/dashboard/masters/costing_report"
        className="categorie-subtitle"
      >
        <div className="child-title">
          <MdOutlineCurrencyRupee size={24} className="childnav-icon" />
          <p className="childnav-name">Costing Report</p>
        </div>
      </NavLink>
      <NavLink
        to="/dashboard/masters/client_wise_sales"
        className="categorie-subtitle"
      >
        <div className="child-title">
          <AiOutlineLineChart size={24} className="childnav-icon" />
          <p className="childnav-name">Client-wise Sales</p>
        </div>
      </NavLink>
      <NavLink
        to="/dashboard/masters/item_wise_sales"
        className="categorie-subtitle"
      >
        <div className="child-title">
          <AiOutlinePieChart size={24} className="childnav-icon" />
          <p className="childnav-name">Item-wise Sales</p>
        </div>
      </NavLink>
      <NavLink
        to="/dashboard/masters/date_wise_sales"
        className="categorie-subtitle"
      >
        <div className="child-title">
          <MdOutlineDateRange size={24} className="childnav-icon" />
          <p className="childnav-name">Date-wise Sales</p>
        </div>
      </NavLink>
      <NavLink
        to="/dashboard/masters/client_wise_pl_report"
        className="categorie-subtitle"
      >
        <div className="child-title">
          <TbReplace size={24} className="childnav-icon" />
          <p className="childnav-name">Client Wise P&L Report</p>
        </div>
      </NavLink>
      <NavLink
        to="/dashboard/masters/product_wise_pl_report"
        className="categorie-subtitle"
      >
        <div className="child-title">
          <TbReportMedical size={24} className="childnav-icon" />
          <p className="childnav-name">Product Wise P&L Report</p>
        </div>
      </NavLink>
      <NavLink
        to="/dashboard/masters/day_wise_pl_report"
        className="categorie-subtitle"
      >
        <div className="child-title">
          <LiaCalendarDaySolid size={24} className="childnav-icon" />
          <p className="childnav-name">Day Wise P&L Report</p>
        </div>
      </NavLink>
    </div>
  );
};

export { ReportsNav };
