import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const ChildNav = ({ childData, childNavClick, ChildNavs }) => {
  const [url, setUrl] = useState("");
  function OnChildNavClicked(name) {
    childNavClick(name);
    setUrl(name);
  }
  console.log(url, childData);
  return (
    <>
      {childData.map((childItem, index) => {
        return (
          <div key={index}>
            <h5>{childItem.SubTitle}</h5>
            {childItem.NavItems.map((item, idx) => {
              return (
                <div
                  key={idx}
                  className={
                    ChildNavs == item.path
                      ? "Configiration-list active"
                      : "Configiration-list"
                  }
                >
                  <NavLink
                    to={item.path}
                    className={
                      ChildNavs == item.path ? "colorChange" : "NoColorChange"
                    }
                    onClick={() => OnChildNavClicked(item.path)}
                  >
                    <span className="configiration-icon">{item.icon}</span>
                    <span className="list-name">{item.label}</span>
                  </NavLink>
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  );
};
export { ChildNav };
