export default function NumberFormat(num) {
  if (isNaN(num)) return ""; // Check if input is a valid number

  const [integerPart, decimalPart] = num.toString().split(".");

  // Format the integer part with commas as per Indian numbering system
  const lastThreeDigits = integerPart.slice(-3);
  const otherDigits = integerPart.slice(0, -3);
  const formattedInteger =
    (otherDigits
      ? otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + ","
      : "") + lastThreeDigits;

  // Combine integer and decimal parts
  const result = decimalPart
    ? `${formattedInteger}.${decimalPart.slice(0, 2)}`
    : `${formattedInteger}.00`;

  // Check for '-,' at the beginning and replace it with '-'
  return result.startsWith("-,") ? result.replace("-,", "-") : result;
}
