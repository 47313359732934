import React, { useState, useEffect } from "react";
import { DashboardLayout } from "../Components";
import { ReportsNav } from "../Components/navbar";
import axiosConfig from "../Service/axiosConfig";
import NumberFormat from "../Utils/NumberFormat";

const CostingReport = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    fromDate: new Date().toISOString().split("T")[0],
    toDate: new Date().toISOString().split("T")[0],
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const GetData = async () => {
    setIsLoading(true);
    try {
      const response = await axiosConfig.get(
        `/accounts/costing_report/?from_date=${formData.fromDate}&to_date=${formData.toDate}`
      );
      setData(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    GetData();
  }, []);

  return (
    <>
      <DashboardLayout>
        <div className="child-sidenav">
          <ReportsNav />
          <div className="flex-1 childnav-content">
            <div className="report-filters">
              <div className="form-group flex-1">
                <input
                  className="form-control"
                  type="date"
                  name="fromDate"
                  value={formData.fromDate}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group flex-1">
                <input
                  className="form-control"
                  type="date"
                  name="toDate"
                  value={formData.toDate}
                  onChange={handleChange}
                />
              </div>
              <div className="">
                {isLoading ? (
                  <img
                    src={require("../Components/FormParser/images/loader.gif")}
                    className="loader-image"
                    alt="loader"
                  />
                ) : (
                  <button
                    className="btn btn-primary"
                    style={{ height: "37px" }}
                    onClick={GetData}
                  >
                    Get Reports
                  </button>
                )}
              </div>
            </div>
            <div className="row" style={{ marginBottom: "16px" }}>
              <div className="col-6 f_14_500">
                Total Helper Charges Rs :
                <span className="f_14_400">
                  {" "}
                  {`₹ ${NumberFormat(data?.total_helper_charges)}`}
                </span>{" "}
              </div>
              <div className="col-6 f_14_500">
                Costing Price Rs :{" "}
                <span className="f_14_400">{`₹ ${NumberFormat(
                  data?.costing_price
                )}`}</span>
              </div>
            </div>
            <div className="row" style={{ marginBottom: "16px" }}>
              <div className="col-6 f_14_500">
                Total Quantity :{" "}
                <span className="f_14_400">{data?.total_quantity}</span>
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export { CostingReport };
